export default [
  {
    path: '/tool',
    name: 'tool',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/system/RoleList.vue'], resolve),
    meta: {
      title: '运营工具',
      icon: 'icon-tool'
    },
    children: [
      {
        path: 'store',
        name: 'store',
        component: (resolve) => require(['@/views/tool/StoreList.vue'], resolve),
        meta: {
          title: '商户管理',
          icon: 'icon-recharge-rule'
        }
      },
      {
        path: 'storeAdd',
        name: 'storeAdd',
        component: (resolve) => require(['@/views/tool/StoreForm.vue'], resolve),
        meta: {
          title: '添加商户',
          hideInMenu: true,
          activeMenu: 'store',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'storeInfo/:id',
        name: 'storeInfo',
        component: (resolve) => require(['@/views/tool/StoreForm.vue'], resolve),
        meta: {
          title: '查看商户',
          hideInMenu: true,
          activeMenu: 'store',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'storeEdit/:id',
        name: 'storeEdit',
        component: (resolve) => require(['@/views/tool/StoreForm.vue'], resolve),
        meta: {
          title: '编辑商户',
          hideInMenu: true,
          activeMenu: 'store',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'shop',
        name: 'shop',
        component: (resolve) => require(['@/views/tool/ShopList.vue'], resolve),
        meta: {
          title: '门店管理',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'shopAdd',
        name: 'shopAdd',
        component: (resolve) => require(['@/views/tool/ShopForm.vue'], resolve),
        meta: {
          title: '添加门店',
          hideInMenu: true,
          activeMenu: 'shop',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'shopInfo/:id',
        name: 'shopInfo',
        component: (resolve) => require(['@/views/tool/ShopForm.vue'], resolve),
        meta: {
          title: '查看门店',
          hideInMenu: true,
          activeMenu: 'shop',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'shopEdit/:id',
        name: 'shopEdit',
        component: (resolve) => require(['@/views/tool/ShopForm.vue'], resolve),
        meta: {
          title: '编辑门店',
          hideInMenu: true,
          activeMenu: 'shop',
          icon: 'icon-priceRule'
        }
      }
    ]
  }
];
