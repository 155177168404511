/**
 * Created by tudou on 2020/4/28 23:42.
 */
export default [
  {
    path: '/recharge',
    name: 'recharge',
    component: { render(h) { return h('router-view'); } },
    meta: {
      title: '充值管理',
      icon: 'icon-recharge'
    },
    children: [
      {
        path: 'rechargeList',
        name: 'rechargeList',
        component: (resolve) => require(['@/views/recharge/RechargeList.vue'], resolve),
        meta: {
          title: '充值列表',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'recharge',
          icon: 'icon-setting'
        }
      },
      {
        path: 'rechargeAdd',
        name: 'rechargeAdd',
        component: (resolve) => require(['@/views/recharge/RechargeForm.vue'], resolve),
        meta: {
          title: '会员充值',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'recharge',
          icon: 'icon-setting'
        }
      },
      {
        path: 'rechargeInfo/:id',
        name: 'rechargeInfo',
        component: (resolve) => require(['@/views/recharge/RechargeForm.vue'], resolve),
        meta: {
          title: '充值详情',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'recharge',
          icon: 'icon-setting'
        }
      },
      // {
      //   path: 'rechargeRule',
      //   name: 'rechargeRule',
      //   component: (resolve) => require(['@/views/recharge/RechargeRule.vue'], resolve),
      //   meta: {
      //     title: '充值规则',
      //     hideInMenu: true,
      //     hideInBread: true,
      //     icon: 'icon-recharge-rule'
      //   }
      // },
    ]
  }
];
