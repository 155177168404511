import store from '../store/index';
// 
import {
  getLocal,
} from '@/libs/utils';
const hasPermission = {
  install(Vue, options) {
    console.log(options);
    Vue.directive('has', {
      inserted: (el, binding, vnode) => {
        console.log(`页面权限控制----${vnode}`);
        // el 当前绑定的元素 binding.value指令的绑定值
        const permissionList = getLocal('subPermissionList');

        // 判断一下是否包含这个元素，如果不包含的话，那就让他爸爸元素把子元素扔进垃圾堆
        if (!permissionList.includes(binding.value)) {
          el.parentNode.removeChild(el);
        }
      }
    });
  }
};

export default hasPermission;
