/**
 * Created by tudou on 2020/4/28 23:40.
 */
export default [
  {
    path: '/member',
    name: 'member',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/member/MemberList.vue'], resolve),
    meta: {
      title: '会员管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'memberList',
        name: 'memberList',
        component: (resolve) => require(['@/views/member/MemberList.vue'], resolve),
        meta: {
          title: '会员列表',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'member',
        }
      },
      {
        path: 'memberAdd',
        name: 'memberAdd',
        component: (resolve) => require(['@/views/member/MemberForm.vue'], resolve),
        meta: {
          title: '添加会员',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'member',
          icon: 'icon-setting'
        }
      },
      {
        path: 'memberEdit/:id',
        name: 'memberEdit',
        component: (resolve) => require(['@/views/member/MemberForm.vue'], resolve),
        meta: {
          title: '编辑会员',
          hideInMenu: true,
          activeMenu: 'member',
          icon: 'icon-setting'
        }
      },
      {
        path: 'memberDiscount/:id',
        name: 'memberDiscount',
        component: (resolve) => require(['@/views/member/MemberDiscount.vue'], resolve),
        meta: {
          title: '会员折扣卡',
          hideInMenu: true,
          activeMenu: 'member',
          icon: 'icon-setting'
        }
      },
      {
        path: 'memberInfo/:id',
        name: 'memberInfo',
        component: (resolve) => require(['@/views/member/MemberForm.vue'], resolve),
        meta: {
          title: '查看会员',
          hideInMenu: true,
          activeMenu: 'member',
          icon: 'icon-setting'
        }
      },
      {
        path: 'memberMatch/:mobile',
        name: 'memberMatch',
        component: (resolve) => require(['@/views/member/MemberMatch.vue'], resolve),
        meta: {
          title: '会员场次',
          hideInMenu: true,
          activeMenu: 'member',
          icon: 'icon-setting'
        }
      },
      {
        path: 'memberScore/:matchSerial/:userName/:openId',
        name: 'memberScore',
        component: (resolve) => require(['@/views/member/MemberScore.vue'], resolve),
        meta: {
          title: '会员成绩',
          hideInMenu: true,
          activeMenu: 'member',
          icon: 'icon-setting'
        }
      },
      {
        path: 'memberBill/:memberId',
        name: 'memberBill',
        component: (resolve) => require(['@/views/member/MemberBill.vue'], resolve),
        meta: {
          title: '消费记录',
          hideInMenu: true,
          activeMenu: 'member',
          icon: 'icon-setting'
        }
      },
      {
        path: 'memberTimesCard/:memberId',
        name: 'memberTimesCard',
        component: (resolve) => require(['@/views/member/MemberTimesCard.vue'], resolve),
        meta: {
          title: '次卡记录',
          hideInMenu: true,
          activeMenu: 'member',
          icon: 'icon-setting'
        }
      },
    ]
  }
];
