/**
 * Created by tudou on 2020/4/28 23:40.
 */
export default [
  {
    path: '/points',
    name: 'points',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/member/MemberList.vue'], resolve),
    meta: {
      title: '积分规则管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'pointsList',
        name: 'pointsList',
        component: (resolve) => require(['@/views/points/PointsList.vue'], resolve),
        meta: {
          title: '积分规则列表',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'points',
        }
      },
      {
        path: 'pointsAdd',
        name: 'pointsAdd',
        component: (resolve) => require(['@/views/points/PointsForm.vue'], resolve),
        meta: {
          title: '添加积分规则',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'points',
          icon: 'icon-setting'
        }
      },
      {
        path: 'pointsEdit/:id',
        name: 'pointsEdit',
        component: (resolve) => require(['@/views/points/PointsForm.vue'], resolve),
        meta: {
          title: '编辑积分规则',
          hideInMenu: true,
          activeMenu: 'points',
          icon: 'icon-setting'
        }
      }
    ]
  }
];
