export default [
  {
    path: '/vehicle',
    name: 'vehicle',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/vehicle/vehicleList.vue'], resolve),
    meta: {
      title: '车辆管理',
      icon: 'icon-car-manager'
    },
    children: [
      {
        path: 'vehicleList',
        name: 'vehicleList',
        component: (resolve) => require(['@/views/vehicle/VehicleList.vue'], resolve),
        meta: {
          title: '车辆列表',
          icon: 'icon-car-list',
          hideInMenu: false,
          hideInBread: false,
        }
      },
      {
        path: 'vehicleStatistics',
        name: 'vehicleStatistics',
        component: (resolve) => require(['@/views/vehicle/VehicleStatistics.vue'], resolve),
        meta: {
          title: '发车统计',
          icon: 'icon-car',
          hideInMenu: false,
          hideInBread: false,
        }
      },
      {
        path: 'vehicleAdd',
        name: 'vehicleAdd',
        component: (resolve) => require(['@/views/vehicle/VehicleForm.vue'], resolve),
        meta: {
          title: '添加车辆',
          hideInMenu: true,
          activeMenu: 'vehicleList',
          icon: 'icon-setting'
        }
      },
      {
        path: 'vehicleEdit/:id',
        name: 'vehicleEdit',
        component: (resolve) => require(['@/views/vehicle/VehicleForm.vue'], resolve),
        meta: {
          title: '编辑车辆',
          hideInMenu: true,
          activeMenu: 'vehicleList',
          icon: 'icon-setting'
        }
      },
      {
        path: 'vehicleInfo/:id',
        name: 'vehicleInfo',
        component: (resolve) => require(['@/views/vehicle/VehicleForm.vue'], resolve),
        meta: {
          title: '查看车辆',
          hideInMenu: true,
          activeMenu: 'vehicleList',
          icon: 'icon-setting'
        }
      },
      {
        path: 'vehicleDetail/:id',
        name: 'vehicleDetail',
        component: (resolve) => require(['@/views/vehicle/VehicleDetail.vue'], resolve),
        meta: {
          title: '车辆详情',
          hideInMenu: true,
          activeMenu: 'vehicleList',
          icon: 'icon-setting'
        }
      },
    ]
  }
];
