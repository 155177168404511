export default [
  {
    path: '/finance',
    name: 'finance',
    component: { render(h) { return h('router-view'); } },
    meta: {
      title: '财务查询',
      icon: 'icon-finance'
    },
    children: [
      {
        path: 'todayRevenue',
        name: 'todayRevenue',
        component: (resolve) => require(['@/views/finance/TodayRevenue.vue'], resolve),
        meta: {
          title: '营收统计',
          hideInMenu: false,
          hideInBread: false,
          icon: 'icon-finance'
        }
      },
      {
        path: 'financeDetailList',
        name: 'financeDetailList',
        component: (resolve) => require(['@/views/finance/FinanceList.vue'], resolve),
        meta: {
          title: '财务明细',
          hideInMenu: false,
          hideInBread: false,
          icon: 'icon-finance'
        }
      },
    ]
  }
];
