export default [
  {
    path: '/level',
    name: 'level',
    component: { render(h) { return h('router-view'); } },
    meta: {
      title: '等级管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'levelList',
        name: 'levelList',
        component: (resolve) => require(['@/views/level/LevelList.vue'], resolve),
        meta: {
          title: '等级列表',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'level',
        }
      },
      {
        path: 'levelAdd',
        name: 'levelAdd',
        component: (resolve) => require(['@/views/level/LevelForm.vue'], resolve),
        meta: {
          title: '添加等级',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'level',
        }
      },
      {
        path: 'levelEdit/:id',
        name: 'levelEdit',
        component: (resolve) => require(['@/views/level/LevelForm.vue'], resolve),
        meta: {
          title: '编辑等级',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'level',
        }
      },
    ]
  }
];
