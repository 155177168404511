export default [
  {
    path: '/timesCard',
    name: 'timesCard',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/system/RoleList.vue'], resolve),
    meta: {
      title: '次卡管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'timesCardPay',
        name: 'timesCardPay',
        component: (resolve) => require(['@/views/timesCard/TimesCardReduce.vue'], resolve),
        meta: {
          title: '次卡消费',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'timesCardRecharge',
        name: 'timesCardRecharge',
        component: (resolve) => require(['@/views/timesCard/TimesCardRecharge.vue'], resolve),
        meta: {
          title: '次卡充值',
          icon: 'icon-setting'
        }
      },
      {
        path: 'timesCardRechargeAdd',
        name: 'timesCardRechargeAdd',
        component: (resolve) => require(['@/views/timesCard/TimesCardRechargeForm.vue'], resolve),
        meta: {
          title: '添加充值',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'timesCardRecharge',
          icon: 'icon-setting'
        }
      },
      {
        path: 'timesCardType',
        name: 'timesCardType',
        component: (resolve) => require(['@/views/timesCard/TimesCardType.vue'], resolve),
        meta: {
          title: '次卡类型',
          icon: 'icon-goods'
        }
      },
      {
        path: 'timesCardTypeAdd',
        name: 'timesCardTypeAdd',
        component: (resolve) => require(['@/views/timesCard/TimesCardTypeForm.vue'], resolve),
        meta: {
          title: '添加次卡类型',
          hideInMenu: true,
          activeMenu: 'timesCardType',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'timesCardTypeEdit/:id',
        name: 'timesCardTypeEdit',
        component: (resolve) => require(['@/views/timesCard/TimesCardTypeForm.vue'], resolve),
        meta: {
          title: '编辑次卡类型',
          hideInMenu: true,
          activeMenu: 'timesCardType',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'timesCardPrice',
        name: 'timesCardPrice',
        component: (resolve) => require(['@/views/timesCard/TimesCardPrice.vue'], resolve),
        meta: {
          title: '次卡价格',
          icon: 'icon-store'
        }
      },
      {
        path: 'timesCardPriceEdit/:id',
        name: 'timesCardPriceEdit',
        component: (resolve) => require(['@/views/timesCard/TimesCardPriceForm.vue'], resolve),
        meta: {
          title: '编辑次卡计费规则',
          hideInMenu: true,
          activeMenu: 'timesCardPrice',
          icon: 'icon-priceRule'
        }
      },
    ]
  }
];
