export default [
  {
    path: '/goods',
    name: 'goods',
    component: { render(h) { return h('router-view'); } },
    meta: {
      title: '商品管理',
      icon: 'icon-goods'
    },
    children: [
      {
        path: 'goodsList',
        name: 'goodsList',
        component: (resolve) => require(['@/views/goods/GoodsList.vue'], resolve),
        meta: {
          title: '商品列表',
          icon: 'icon-car',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'goods',
        }
      },
      {
        path: 'goodsAdd',
        name: 'goodsAdd',
        component: (resolve) => require(['@/views/goods/GoodsForm.vue'], resolve),
        meta: {
          title: '添加商品',
          hideInMenu: true,
          activeMenu: 'goods',
          icon: 'icon-setting'
        }
      },
      {
        path: 'goodsEdit/:id',
        name: 'goodsEdit',
        component: (resolve) => require(['@/views/goods/GoodsForm.vue'], resolve),
        meta: {
          title: '编辑商品',
          hideInMenu: true,
          activeMenu: 'goods',
          icon: 'icon-setting'
        }
      },
      {
        path: 'goodsInfo/:id',
        name: 'goodsInfo',
        component: (resolve) => require(['@/views/goods/GoodsForm.vue'], resolve),
        meta: {
          title: '查看商品',
          hideInMenu: true,
          activeMenu: 'goods',
          icon: 'icon-setting'
        }
      },
    ]
  }
];
