export default [
  {
    path: '/vip',
    name: 'vip',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/system/RoleList.vue'], resolve),
    meta: {
      title: '年卡管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'vipRecharge',
        name: 'vipRecharge',
        component: (resolve) => require(['@/views/vip/VipRecharge.vue'], resolve),
        meta: {
          title: '年卡充值',
          icon: 'icon-setting'
        }
      },
      {
        path: 'vipRechargeAdd',
        name: 'vipRechargeAdd',
        component: (resolve) => require(['@/views/vip/VipRechargeForm.vue'], resolve),
        meta: {
          title: '添加充值',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'vipRecharge',
          icon: 'icon-setting'
        }
      },
      {
        path: 'vipList',
        name: 'vipList',
        component: (resolve) => require(['@/views/vip/VipList.vue'], resolve),
        meta: {
          title: '年卡列表',
          icon: 'icon-goods'
        }
      },
      {
        path: 'vipEdit/:id',
        name: 'vipEdit',
        component: (resolve) => require(['@/views/vip/VipForm.vue'], resolve),
        meta: {
          title: '价格编辑',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'vipList',
        }
      },
    ]
  }
];
