/**
 * Created by ZJ on 2021/12/09 10:37.
 */
export default [
  {
    path: '/coupon',
    name: 'coupon',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/member/MemberList.vue'], resolve),
    meta: {
      title: '优惠券管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'couponList',
        name: 'couponList',
        component: (resolve) => require(['@/views/coupon/couponList.vue'], resolve),
        meta: {
          title: '优惠券列表',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'coupon',
        }
      },
      {
        path: 'recordList',
        name: 'recordList',
        component: (resolve) => require(['@/views/coupon/recordList.vue'], resolve),
        meta: {
          title: '优惠券列表',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'coupon',
        }
      }
    ]
  }
];
