/**
 * Created by tudou on 2020/5/9 22:27.
 */
export default {
  orderStatus: new Map([
    [0, '待确认'],
    [1, '待支付'],
    // [2, '部分支付'],
    [4, '已支付'],
    [10, '已完成'],
    // [7, '部分退款'],
    [8, '已退款'],
    [9, '已取消']
  ])
};
