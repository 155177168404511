export default [
  {
    path: '/fee',
    name: 'fee',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/system/RoleList.vue'], resolve),
    meta: {
      title: '会费管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'feeRecharge',
        name: 'feeRecharge',
        component: (resolve) => require(['@/views/fee/FeeRecharge.vue'], resolve),
        meta: {
          title: '会费充值',
          icon: 'icon-setting'
        }
      },
      {
        path: 'feeRechargeAdd',
        name: 'feeRechargeAdd',
        component: (resolve) => require(['@/views/fee/FeeRechargeForm.vue'], resolve),
        meta: {
          title: '添加充值',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'feeRecharge',
          icon: 'icon-setting'
        }
      },
      {
        path: 'feePrice',
        name: 'feePrice',
        component: (resolve) => require(['@/views/fee/FeePrice.vue'], resolve),
        meta: {
          title: '会费价目',
          icon: 'icon-goods'
        }
      },
      {
        path: 'feePriceAdd',
        name: 'feePriceAdd',
        component: (resolve) => require(['@/views/fee/FeePriceForm.vue'], resolve),
        meta: {
          title: '添加会费价格',
          hideInMenu: true,
          activeMenu: 'feePrice',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'feePriceEdit/:id',
        name: 'feePriceEdit',
        component: (resolve) => require(['@/views/fee/FeePriceForm.vue'], resolve),
        meta: {
          title: '编辑会费价格',
          hideInMenu: true,
          activeMenu: 'feePrice',
          icon: 'icon-priceRule'
        }
      },
    ]
  }
];
