export default [
  {
    path: '/car',
    name: 'car',
    component: { render(h) { return h('router-view'); } },
    meta: {
      title: '车辆信息',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'carList',
        name: 'carList',
        component: (resolve) => require(['@/views/carInfo/CarInfoList.vue'], resolve),
        meta: {
          title: '车辆信息列表',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'carInfo',
        }
      },
      {
        path: 'carAdd',
        name: 'carAdd',
        component: (resolve) => require(['@/views/carInfo/CarInfoForm.vue'], resolve),
        meta: {
          title: '添加车辆信息',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'car',
        }
      },
      {
        path: 'carInfoEdit/:id',
        name: 'carInfoEdit',
        component: (resolve) => require(['@/views/carInfo/CarInfoForm.vue'], resolve),
        meta: {
          title: '编辑车辆信息',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'car',
        }
      },
    ]
  }
];
