export default [
  {
    path: '/promotion',
    name: 'promotion',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/member/MemberList.vue'], resolve),
    meta: {
      title: '同行立减规则管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'promotionList',
        name: 'promotionList',
        component: (resolve) => require(['@/views/promotion/PromotionList.vue'], resolve),
        meta: {
          title: '同行立减规则列表',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'promotion',
        }
      },
      {
        path: 'promotionAdd',
        name: 'promotionAdd',
        component: (resolve) => require(['@/views/promotion/PromotionForm.vue'], resolve),
        meta: {
          title: '添加同行立减规则',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'promotion',
          icon: 'icon-setting'
        }
      },
      {
        path: 'promotionEdit/:id',
        name: 'promotionEdit',
        component: (resolve) => require(['@/views/promotion/PromotionForm.vue'], resolve),
        meta: {
          title: '编辑同行立减规则',
          hideInMenu: true,
          activeMenu: 'promotion',
          icon: 'icon-setting'
        }
      }
    ]
  }
];
