/**
 * Created by tudou on 2020/4/10 20:39.
 */
const isDev = process.env.NODE_ENV === 'development';

// 开启权限路由验证 !!! 线上必须开启
const isAuth = !isDev;

// 默认语言的标识 zh_CN中文 en_US英文
const defaultLang = 'zh_CN';

// 默认语言存储在localstorage的key
const localeKey = 'locale';

// 用户登录标识
const tokenKey = 'token';

// 接口请求地址
const devURL = '/api';
const proURL = '';
const baseURL = process.env.NODE_ENV === 'development' ? devURL : proURL;

// 微信跳转地址
const wxUrl = window.location.protocol + '//' + window.location.host + '/api/wx/auth/authorize?type=1&content=';

// 小程序跳转地址
const devWeAppPath = 'https://test.nice-timing.com/weApp/';
const prodWeAppPath = 'https://pay.nice-timing.com/weApp/';
const weAppPath = isDev ? devWeAppPath : prodWeAppPath;

// 上传文件默认限制的大小: 2M
const maxSize = 1024 * 2;

// 充值规则条数上限
const rechargeRuleCount = 10;

// 图表的颜色列表
const colorData = ['#0094da', '#5bc0de', '#e79d47', '#f00', '#5cb85c', '#f4a79d'];

export {
  isDev,
  isAuth,
  defaultLang,
  localeKey,
  tokenKey,
  baseURL,
  wxUrl,
  weAppPath,
  rechargeRuleCount,
  maxSize,
  colorData,
};
