/**
 * Created by tudou on 2020/4/25 11:16.
 */
import axios from '@/libs/axios';

const captchaCode = (params) => {
  // return axios.get('/api/captcha/code', params);
  return axios.get('/api/captcha/codebase64', params);
};

const login = (params) => {
  // return axios.get('/login.json', params);
  return axios.post('/api/login', params);
};

const permissionList = (params) => {
  // return axios.get('/api/user/permission', params);
  return new Promise((resolve) => {
    const data = {
      "message": "查询成功",
      "code": 0,
      "data": {
        "menu": [
          {
            "id": "1",
            "path": "order",
            "name": '订单管理',
            "children": [
              {
                "id": "1-1",
                "path": "orderList",
                "name": '订单列表'
              },
              {
                "id": "1-2",
                "path": "orderInfo",
                "name": "订单详情"
              },
              {
                "id": "1-3",
                "path": "orderEdit",
                "name": "编辑订单"
              }
            ]
          },
          {
            "id": "2",
            "path": "recharge",
            "name": '充值管理',
            "children": [
              {
                "id": "2-1",
                "path": "rechargeList",
                "name": '充值列表'
              },
              {
                "id": "2-2",
                "path": "rechargeAdd",
                "name": "会员充值"
              },
              {
                "id": "2-3",
                "path": "rechargeInfo",
                "name": "充值详情"
              }
            ]
          },
          {
            "id": "3",
            "path": "member",
            "name": '会员管理',
            "children": [
              {
                "id": "3-1",
                "path": "memberList",
                "name": '会员列表'
              },
              {
                "id": "3-2",
                "path": "memberAdd",
                "name": "添加会员"
              },
              {
                "id": "3-3",
                "path": "memberEdit",
                "name": "编辑会员"
              },
              {
                "id": "3-4",
                "path": "memberInfo",
                "name": "查看会员"
              }
            ]
          },
          {
            "id": "4",
            "path": "vehicle",
            "name": '车辆管理',
            "children": [
              {
                "id": "4-1",
                "path": "vehicleList",
                "name": '车辆列表'
              },
              {
                "id": "4-2",
                "path": "vehicleAdd",
                "name": "添加车辆"
              },
              {
                "id": "4-3",
                "path": "vehicleEdit",
                "name": "编辑车辆"
              },
              {
                "id": "4-4",
                "path": "vehicleInfo",
                "name": "查看车辆"
              }
            ]
          },
          {
            "id": "5",
            "path": "finance",
            "name": '财务查询',
            "children": [
              {
                "id": "5-1",
                "path": "financeList",
                "name": '财务查询列表'
              }
            ]
          },
          {
            "id": "6",
            "path": "system",
            "name": '系统设置',
            "children": [
              {
                "id": "6-1",
                "path": "role",
                "name": '角色创建'
              },
              {
                "id": "6-2",
                "path": "roleAdd",
                "name": "添加角色"
              },
              {
                "id": "6-3",
                "path": "roleEdit",
                "name": "编辑角色"
              },
              {
                "id": "6-4",
                "path": "user",
                "name": "权限分配"
              },
              {
                "id": "6-5",
                "path": "userAdd",
                "name": "添加用户"
              },
              {
                "id": "6-6",
                "path": "userEdit",
                "name": "编辑用户"
              },
              {
                "id": "6-7",
                "path": "priceRule",
                "name": "计费规则"
              },
              {
                "id": "6-8",
                "path": "priceRuleAdd",
                "name": "添加计费规则"
              },
              {
                "id": "6-9",
                "path": "priceRuleInfo",
                "name": "查看计费规则"
              },
              {
                "id": "6-10",
                "path": "priceRuleEdit",
                "name": "编辑计费规则"
              },
              {
                "id": "6-11",
                "path": "chargeRule",
                "name": "充值规则"
              },
              {
                "id": "6-12",
                "path": "chargeRuleAdd",
                "name": "添加充值规则"
              },
              {
                "id": "6-13",
                "path": "chargeRuleInfo",
                "name": "查看充值规则"
              },
              {
                "id": "6-14",
                "path": "chargeRuleEdit",
                "name": "编辑充值规则"
              },
              {
                "id": "6-15",
                "path": "store",
                "name": "门店管理"
              },
              {
                "id": "6-16",
                "path": "storeAdd",
                "name": "添加门店"
              },
              {
                "id": "6-17",
                "path": "storeInfo",
                "name": "查看门店"
              },
              {
                "id": "6-18",
                "path": "storeEdit",
                "name": "编辑门店"
              },
              {
                "id": "6-19",
                "path": "systemStore",
                "name": "系统设置"
              },
            ]
          }
        ]
      }
    };

    resolve(data.data);
  })
};


// 角色列表
const roleList = (data) => {
  return axios.get('/api/sys/role/list', data);
};

const selectRoleList = (data) => {
  return axios.get('/api/sys/role/selectRoleList', data);
};

// 启用/禁用角色
const roleStatus = (data) => {
  return axios.get('/api/sys/role/status', data);
};

// 角色信息
const roleInfo = (roleId) => {
  return axios.get(`/api/sys/role/info/${roleId}`);
};

// 所有权限角色
const roleOwnAll = (data) => {
  return axios.get(`/api/sys/menu/ownall`);
};

// 保存角色信息
const roleSubmit = (params) => {
  return axios.post('/api/sys/role/save', params);
};

// 用户列表
const systemUserList = (data) => {
  return axios.get('/api/sys/user/list', data);
};

// 用户信息
const sysUserInfo = (userId) => {
  return axios.get(`/api/sys/user/info/${userId}`);
};

// 保存用户信息
const sysUserSubmit = (params) => {
  return axios.post('/api/sys/user/save', params);
};

// 更新用户信息
const sysUserUpdate = (params) => {
  return axios.post('/api/sys/user/update', params);
};

// 修改用户密码
const sysUserUpdatepwd = (params) => {
  return axios.post('/api/sys/user/updatepwd', params);
};

// 启用/禁用用户
const systemUserStatus = (data) => {
  return axios.get('/api/sys/user/status', data);
};

// 修改当前登录用户密码
const changepwd = (params) => {
  return axios.post('/api/sys/user/changepwd', params);
};

const sendsms = (params) => {
  return axios.post('/api/message/send', params);
};

// 用户列表
const sysStoreSubUserList = (data) => {
  return axios.get('/api/sys/user/storeSubList', data);
};

// 保存用户信息
const sysStoreSubUserSubmit = (params) => {
  return axios.post('/api/sys/user/storeSubSave', params);
};

export {
  login,
  captchaCode,
  permissionList,
  systemUserList,
  roleList,
  selectRoleList,
  roleStatus,
  roleInfo,
  roleSubmit,
  roleOwnAll,
  sysUserInfo,
  sysUserSubmit,
  sysUserUpdate,
  sysUserUpdatepwd,
  systemUserStatus,
  changepwd,
  sendsms,
  sysStoreSubUserList,
  sysStoreSubUserSubmit
};
