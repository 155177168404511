export default [
  {
    path: '/gift',
    name: 'gift',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/system/RoleList.vue'], resolve),
    meta: {
      title: '礼品卡',
      icon: 'icon-tool'
    },
    children: [
      {
        path: 'giftActive',
        name: 'giftActive',
        component: (resolve) => require(['@/views/gift/ActiveGift.vue'], resolve),
        meta: {
          title: '礼品卡激活',
          icon: 'icon-recharge-rule',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'gift',
        }
      }
    ]
  }
];
