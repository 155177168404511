/**
 * Created by tudou on 2020/4/10 18:11.
 */
import {
  getLocal,
  setLocal,
  getCookie,
  setCookie
} from '@/libs/utils';
import { defaultLang, tokenKey, localeKey } from '@/libs/config';

export default {
  namespace: true,
  state: {
    // 当前选中的菜单
    currentMenu: [],

    // 菜单列表
    menuList: [],

    // 登录用户信息
    userInfo: null,

    // 登录门店信息
    storeInfo: null,

    // 权限列表
    permissionList: [],
    // 页面权限列表
    subpermissionList: [],
    // 面包屑导航
    breadCrumbList: [],

    // 多语言支持 zh_CN || en_US
    locale: defaultLang,

    // 全局加载状态
    loading: false,

    // 是否禁用全局加载状态
    disableLoading: false,
  },
  getters: {
    getLocale(state) {
      return getLocal(localeKey) || state.locale;
    },
    getSubPermissionList(state) {
      return state.permissionList || getLocal('subPermissionList');
    },
    getUserInfo(state) {
      return state.userInfo || getLocal('userInfo');
    },
    getStoreInfo(state) {
      return state.storeInfo || getLocal('storeInfo');
    },
    getBreadcrumbName(state) {
      return state.breadCrumbList[state.breadCrumbList.length - 1].breadcrumbName;
    },

    // 获取用户登录标识
    getToken(state) {
      return state.token || getCookie(tokenKey);
    },

  },

  // 同步更改state的方法
  mutations: {
    // 设置当前菜单
    setCurrentMenu(state, name) {
      state.currentMenu = name;
    },

    // 设置菜单列表
    setMenuList(state, list) {
      state.menuList = list;
    },

    // 设置登录用户信息
    setUserInfo(state, data) {
      state.userInfo = data;
      setLocal('userInfo', data);
    },
    // 设置登录门店信息
    setStoreInfo(state, data) {
      state.storeInfo = data;
      setLocal('storeInfo', data);
    },

    // 设置token
    setToken(state, data) {
      state.token = data;
      setCookie(tokenKey, data);
    },

    // 设置权限路由列表
    setPermissionList(state, list) {
      state.permissionList = list;
    },
    // 设置页面权限列表
    setSubPermissionList(state, list) {
      console.log(`setSubPermissionList${list}`);
      state.subpermissionList = list;
      setLocal('subPermissionList', list);
    },
    // 设置面包屑导航列表
    setBreadCrumbList(state, list) {
      state.breadCrumbList = list;
    },

    // 切换多语言
    setLocale(state, payload) {
      state.locale = payload;
      setLocal(localeKey, payload);
    },

    // 设置全局loading状态
    setLoading(state, status) {
      state.loading = status;
    },

    // 设置是否禁用全局loading状态
    setDisableLoading(state, status) {
      state.disableLoading = status;
    },
  },

  // 异步更改state，只能提交到mutations
  actions: {
  }
};
