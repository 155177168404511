export default [
  {
    path: '/system',
    name: 'system',
    component: { render(h) { return h('router-view'); } },
    // component: (resolve) => require(['@/views/system/RoleList.vue'], resolve),
    meta: {
      title: '系统设置',
      icon: 'icon-setting'
    },
    children: [
      {
        path: 'role',
        name: 'role',
        component: (resolve) => require(['@/views/system/RoleList.vue'], resolve),
        meta: {
          title: '角色创建',
          icon: 'icon-role'
        }
      },
      {
        path: 'roleAdd',
        name: 'roleAdd',
        component: (resolve) => require(['@/views/system/RoleForm.vue'], resolve),
        meta: {
          title: '添加角色',
          hideInMenu: true,
          activeMenu: 'role',
          icon: 'icon-setting'
        }
      },
      {
        path: 'roleEdit/:id',
        name: 'roleEdit',
        component: (resolve) => require(['@/views/system/RoleForm.vue'], resolve),
        meta: {
          title: '编辑角色',
          hideInMenu: true,
          activeMenu: 'role',
          icon: 'icon-setting'
        }
      },
      {
        path: 'roleInfo/:id',
        name: 'roleInfo',
        component: (resolve) => require(['@/views/system/RoleForm.vue'], resolve),
        meta: {
          title: '查看角色',
          hideInMenu: true,
          activeMenu: 'role',
          icon: 'icon-setting'
        }
      },
      {
        path: 'user',
        name: 'user',
        component: (resolve) => require(['@/views/system/UserList.vue'], resolve),
        meta: {
          title: '权限分配',
          icon: 'icon-user'
        }
      },
      {
        path: 'userAdd',
        name: 'userAdd',
        component: (resolve) => require(['@/views/system/UserForm.vue'], resolve),
        meta: {
          title: '添加用户',
          hideInMenu: true,
          activeMenu: 'user',
          icon: 'icon-setting'
        }
      },
      {
        path: 'userEdit/:id',
        name: 'userEdit',
        component: (resolve) => require(['@/views/system/UserForm.vue'], resolve),
        meta: {
          title: '编辑用户',
          hideInMenu: true,
          activeMenu: 'user',
          icon: 'icon-setting'
        }
      },
      {
        path: 'userInfo/:id',
        name: 'userInfo',
        component: (resolve) => require(['@/views/system/UserForm.vue'], resolve),
        meta: {
          title: '查看用户',
          hideInMenu: true,
          activeMenu: 'user',
          icon: 'icon-setting'
        }
      },
      {
        path: 'SysCategory',
        name: 'SysCategory',
        component: (resolve) => require(['@/views/system/SysCategoryList.vue'], resolve),
        meta: {
          title: '商品字典',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'SysCarCategory',
        name: 'SysCarCategory',
        component: (resolve) => require(['@/views/system/SysCategoryCarList.vue'], resolve),
        meta: {
          title: '车辆字典',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'SysDictCategory',
        name: 'SysDictCategory',
        component: (resolve) => require(['@/views/system/SysCategoryDictList.vue'], resolve),
        meta: {
          title: '数据标签字典',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'SysDict',
        name: 'SysDict',
        component: (resolve) => require(['@/views/system/DictList.vue'], resolve),
        meta: {
          title: '数据字典',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'vehicleCategory',
        name: 'vehicleCategory',
        component: (resolve) => require(['@/views/system/VehicleCategory.vue'], resolve),
        meta: {
          title: '车辆类型',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'vehicleCategoryAdd',
        name: 'vehicleCategoryAdd',
        component: (resolve) => require(['@/views/system/VehicleCategoryForm.vue'], resolve),
        meta: {
          title: '添加车辆类型',
          hideInMenu: true,
          activeMenu: 'vehicleCategory',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'vehicleCategoryInfo/:id',
        name: 'vehicleCategoryInfo',
        component: (resolve) => require(['@/views/system/VehicleCategoryForm.vue'], resolve),
        meta: {
          title: '查看车辆类型',
          hideInMenu: true,
          activeMenu: 'vehicleCategory',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'vehicleCategoryEdit/:id',
        name: 'vehicleCategoryEdit',
        component: (resolve) => require(['@/views/system/VehicleCategoryForm.vue'], resolve),
        meta: {
          title: '编辑车辆类型',
          hideInMenu: true,
          activeMenu: 'vehicleCategory',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'priceRule',
        name: 'priceRule',
        component: (resolve) => require(['@/views/system/PriceRuleList.vue'], resolve),
        meta: {
          title: '计费规则',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'priceRuleAdd',
        name: 'priceRuleAdd',
        component: (resolve) => require(['@/views/system/PriceRuleForm.vue'], resolve),
        meta: {
          title: '添加计费规则',
          hideInMenu: true,
          activeMenu: 'priceRule',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'priceRuleInfo/:id',
        name: 'priceRuleInfo',
        component: (resolve) => require(['@/views/system/PriceRuleForm.vue'], resolve),
        meta: {
          title: '查看计费规则',
          hideInMenu: true,
          activeMenu: 'priceRule',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'priceRuleEdit/:id',
        name: 'priceRuleEdit',
        component: (resolve) => require(['@/views/system/PriceRuleForm.vue'], resolve),
        meta: {
          title: '编辑计费规则',
          hideInMenu: true,
          activeMenu: 'priceRule',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'chargeRule',
        name: 'chargeRule',
        component: (resolve) => require(['@/views/system/ChargeRuleList.vue'], resolve),
        meta: {
          title: '充值规则',
          icon: 'icon-recharge-rule'
        }
      },
      {
        path: 'chargeRuleAdd',
        name: 'chargeRuleAdd',
        component: (resolve) => require(['@/views/system/ChargeRuleForm.vue'], resolve),
        meta: {
          title: '添加充值规则',
          hideInMenu: true,
          activeMenu: 'chargeRule',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'chargeRuleInfo/:id',
        name: 'chargeRuleInfo',
        component: (resolve) => require(['@/views/system/ChargeRuleForm.vue'], resolve),
        meta: {
          title: '查看充值规则',
          hideInMenu: true,
          activeMenu: 'chargeRule',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'chargeRuleEdit/:id',
        name: 'chargeRuleEdit',
        component: (resolve) => require(['@/views/system/ChargeRuleForm.vue'], resolve),
        meta: {
          title: '编辑充值规则',
          hideInMenu: true,
          activeMenu: 'chargeRule',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'store22',
        name: 'store22',
        component: (resolve) => require(['@/views/system/store/StoreList.vue'], resolve),
        meta: {
          title: '门店管理',
          icon: 'icon-recharge-rule'
        }
      },
      {
        path: 'storeAdd2',
        name: 'storeAdd2',
        component: (resolve) => require(['@/views/system/store/StoreForm.vue'], resolve),
        meta: {
          title: '添加门店',
          hideInMenu: true,
          activeMenu: 'store',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'storeInfo/:id',
        name: 'storeInfo1',
        component: (resolve) => require(['@/views/system/store/StoreForm.vue'], resolve),
        meta: {
          title: '查看门店',
          hideInMenu: true,
          activeMenu: 'store',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'storeEdit/:id',
        name: 'storeEdit1',
        component: (resolve) => require(['@/views/system/store/StoreForm.vue'], resolve),
        meta: {
          title: '编辑门店',
          hideInMenu: true,
          activeMenu: 'store',
          icon: 'icon-priceRule'
        }
      },
      {
        path: 'systemStore',
        name: 'systemStore',
        component: (resolve) => require(['@/views/system/SystemStoreForm.vue'], resolve),
        meta: {
          title: '门店设置',
          icon: 'icon-store'
        }
      },
      {
        path: 'storeRole',
        name: 'storeRole',
        component: (resolve) => require(['@/views/system/store/StoreRoleList.vue'], resolve),
        meta: {
          title: '商户角色创建',
          icon: 'icon-role'
        }
      },
      {
        path: 'storeRoleAdd',
        name: 'storeRoleAdd',
        component: (resolve) => require(['@/views/system/store/StoreRoleForm.vue'], resolve),
        meta: {
          title: '添加商户角色',
          hideInMenu: true,
          activeMenu: 'storeRole',
          icon: 'icon-setting'
        }
      },
      {
        path: 'storeRoleEdit/:id',
        name: 'storeRoleEdit',
        component: (resolve) => require(['@/views/system/store/StoreRoleForm.vue'], resolve),
        meta: {
          title: '编辑商户角色',
          hideInMenu: true,
          activeMenu: 'storeRole',
          icon: 'icon-setting'
        }
      },
      {
        path: 'storeRoleInfo/:id',
        name: 'storeRoleInfo',
        component: (resolve) => require(['@/views/system/store/StoreRoleForm.vue'], resolve),
        meta: {
          title: '查看商户角色',
          hideInMenu: true,
          activeMenu: 'storeRole',
          icon: 'icon-setting'
        }
      },
      {
        path: 'storeUser',
        name: 'storeUser',
        component: (resolve) => require(['@/views/system/store/StoreUserList.vue'], resolve),
        meta: {
          title: '商户子账号创建',
          icon: 'icon-user'
        }
      },
      {
        path: 'storeUserAdd',
        name: 'storeUserAdd',
        component: (resolve) => require(['@/views/system/store/StoreUserForm.vue'], resolve),
        meta: {
          title: '添加商户子账号',
          hideInMenu: true,
          activeMenu: 'storeUser',
          icon: 'icon-setting'
        }
      },
      {
        path: 'storeUserEdit/:id',
        name: 'storeUserEdit',
        component: (resolve) => require(['@/views/system/store/StoreUserForm.vue'], resolve),
        meta: {
          title: '编辑商户子账号',
          hideInMenu: true,
          activeMenu: 'storeUser',
          icon: 'icon-setting'
        }
      },
    ]
  }
];
